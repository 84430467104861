<template>
  <div v-if="length === 0" class="container">
    <div v-if="!loading && length === 0 && !hasFilter">
      <p>{{ emptyMessage }}</p>
    </div>
    <div v-if="!loading && length === 0 && hasFilter">
      <p>Os filtros aplicados não geraram nenhum resultado.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    emptyMessage: String,
    loading: Boolean,
    length: Number,
    hasFilter: { validate: v => true },
  }
}
</script>
<style lang="scss" scoped>
  .container {
    display: flex;
    justify-content: center;
        
    p {
      padding: 64px 0;
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 132%;
      color: var(--type-placeholder);
    }
  }
</style>
